'use client';

import { FC, SVGProps } from 'react';

const IconArrowXxlButton: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" {...props}>
      <path d="M5.04907 11.0073H17.8836" stroke="currentColor" fill="none" strokeWidth="1.83351" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.4663 4.59009L17.8836 11.0074L11.4663 17.4246" stroke="currentColor" fill="none" strokeWidth="1.83351" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default IconArrowXxlButton;
