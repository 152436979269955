'use client';

import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { FC, useEffect, useRef } from 'react';
import animationData from '@/animations/feature-shared-inbox.json';

export interface AnimationFeatureSharedInboxProps {
	playing?: boolean;
}

const AnimationFeatureSharedInbox: FC<AnimationFeatureSharedInboxProps> = ({ playing }) => {
	const animationRef = useRef<LottieRefCurrentProps | null>(null);

	useEffect(() => {
		if (!animationRef.current) return;

		if (playing) {
			animationRef.current.play();
		} else {
			animationRef.current.pause();
		}
	}, [playing]);

	return (
		<div className="aspect-[396/348]">
			<Lottie animationData={animationData} autoplay={false} lottieRef={animationRef} />
		</div>
	);
};

export default AnimationFeatureSharedInbox;
