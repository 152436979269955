'use client';

import useStore from '@/stores/useStore';
import cx from 'classnames';
import { FC } from 'react';
import Link from './Link';

export interface HomeHeroProps {
  title: React.ReactNode;
  description: string;

  links: {
    type: 'primary' | 'secondary';
    title: string;
    href: string;
    action?: 'trial' | 'demo';
  }[];

  media: React.ReactNode;

  reviews: {
    title: string;
    subtitle: string;
    image: React.ReactNode;
  }[];

  logosTitle: string;
  companyLogos: React.ReactNode[];
}

const HomeHero: FC<HomeHeroProps> = ({
  title,
  description,
  links = [],
  reviews = [],
  media,
  logosTitle,
  companyLogos = [],
}) => {
  const { setDemoModalOpen, setTrialModalOpen } = useStore();

  const onClickMenuButton = (action) => {
    switch (action) {
      case 'trial':
        setTrialModalOpen(true);
        break;
      case 'demo':
        setDemoModalOpen(true);
        break;

      default:
        break;
    }
  };

  const header = (
    <div className="flex flex-col gap-2 md:gap-3">
      <h1 className="font-500 tracking-tight text-36/42 md:text-54/54">{title}</h1>
      <p className="text-violet-200 text-17/24">{description}</p>
      <ul className="flex gap-1 md:gap-2">
        {links.map((link, index) => {
          return (
            <li key={index}>
              {link.href.length ? (
                <Link
                  key={index}
                  className={cx('block button button-size-medium', {
                    'button-primary-white': link.type === 'primary',
                    'button-secondary-violet-300': link.type === 'secondary',
                  })}
                  href={link.href}
                >
                  {link.title}
                </Link>
              ) : (
                <button
                  key={index}
                  className={cx('block button button-size-medium', {
                    'button-primary-white': link.type === 'primary',
                    'button-secondary-violet-300': link.type === 'secondary',
                  })}
                  onClick={() => {
                    onClickMenuButton(link.action);
                  }}
                >
                  {link.title}
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );

  const reviewsGrid = (
    <ul className="flex justify-end gap-4 text-violet-200 items-center">
      {reviews.map((review, index) => (
        <li key={index} className="flex gap-2 items-center">
          {review.image}
          <p>{review.title}</p>
          <p>{review.subtitle}</p>
        </li>
      ))}
    </ul>
  );

  const logoFooter = (
    <div className="flex flex-col gap-6 md:gap-8">
      <h2 className="text-25/30 font-500 text-center">{logosTitle}</h2>
      <div className="grid grid-cols-2 gap-x-gutter-mobile gap-y-4 md:flex md:gap-10 md:flex-wrap md:items-center md:justify-center">
        {companyLogos}
      </div>
    </div>
  );

  return (
    <div className="bg-violet-900 text-white">
      <div className="-mt-header-height-mobile md:-mt-header-height-desktop pt-header-height-mobile md:pt-header-height-desktop">
        <div className="pt-11 md:pt-12 px-container-mobile md:px-container-desktop pb-10">
          <div className="mx-auto max-w-grid">
            <div className="grid grid-cols-4 md:grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop items-end">
              <div className="col-span-4 md:col-span-8 lg:col-span-7 lg:pr-8">{header}</div>
              <div className="hidden lg:block col-span-4 lg:col-span-5">{reviewsGrid}</div>
            </div>

            <div className="my-6 md:my-4">{media}</div>

            <div className="mt-6 md:mt-10">{logoFooter}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
