'use client';

import ease from '@/constants/ease';
import useStore from '@/stores/useStore';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useState } from 'react';
import { useIntersectionObserverRef } from 'rooks';

export interface CtaLinkProps {
  type: 'primary' | 'secondary';
  title: string;
  href: string;
  action: 'trial' | 'demo';
}

export interface PageBumperProps {
  title: string;
  links: CtaLinkProps[];
}

export const PageBumper: FC<PageBumperProps> = ({ title, links = [] }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasSeen, setHasSeen] = useState(false);
  const { setDemoModalOpen, setTrialModalOpen } = useStore();

  const callback = (entries) => {
    let isIntersecting = false;

    if (entries && entries[0]) {
      isIntersecting = entries[0].isIntersecting;
    }

    setIsVisible(isIntersecting);

    if (isIntersecting) {
      setHasSeen(true);
    }
  };

  const modalActionHandler = (action) => {
    switch (action) {
      case 'trial':
        setTrialModalOpen(true);
        break;
      case 'demo':
        setDemoModalOpen(true);
        break;
      default:
        break;
    }
  };

  const [ref] = useIntersectionObserverRef(callback, { threshold: 0.1 });

  const ctaCol = (
    <div className="xl:col-span-6">
      <div className="px-3 py-6 lg:px-10 lg:py-12 bg-blue-100 rounded-10">
        <div className="flex flex-col gap-3 md:gap-2">
          <p className="font-500 text-30/35 md:text-36/42 tracking-tight">{title}</p>
          <ul className="flex flex-col gap-2 md:flex-row items-start md:items-center">
            {links.map((link, index) => (
              <li key={index}>
                {link.href.length ? (
                  <Link
                    key={index}
                    className={cx('block', {
                      'button button-size-medium button-primary-violet': link.type === 'primary',
                      'button button-size-medium button-secondary-violet': link.type === 'secondary',
                    })}
                    href={link.href}
                  >
                    {link.title}
                  </Link>
                ) : (
                  <button
                    key={index}
                    className={cx('block', {
                      'button button-size-medium button-primary-violet': link.type === 'primary',
                      'button button-size-medium button-secondary-violet': link.type === 'secondary',
                    })}
                    onClick={() => {
                      modalActionHandler(link.action);
                    }}
                  >
                    {link.title}
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

  const animationColumn = (
    <div className="relative hidden xl:block col-span-6 pointer-events-none">
      <div className="absolute inset-0 overflow-hidden rounded-10">
        <Image
          src="/images/bumper-bg.jpg"
          alt=""
          className="absolute left-0 right-0 bottom-0 w-full"
          width={675}
          height={325}
        />
      </div>
      <div className="absolute left-0 right-0 bottom-0 top-[-100%] flex flex-col justify-end pl-[130px] rounded-10 overflow-hidden">
        <div className="absolute bottom-[0.3%] w-[80.1%] right-[-0.05%] h-auto">
          <Image src="/images/bumper-cover.png" alt="" className="w-full h-auto" width={501} height={403} />
        </div>
      </div>
      <div className="absolute inset-0 flex flex-col justify-end items-center pb-[15%]">
        <AnimatePresence>
          {hasSeen && (
            <motion.div
              initial={{ opacity: 0, y: '100%' }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                ease: ease.appear,
                duration: 400 / 1000,
                delay: 800 / 1000,
              }}
              className="px-[15%]"
            >
              <Image src="/images/bumper-notification.svg" alt="" width={430} height={63} className="w-full h-auto" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );

  return (
    <div ref={ref} className="my-16 md:my-22 px-container-mobile md:px-container-desktop">
      <div className="mx-auto max-w-grid">
        <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop">
          {ctaCol}
          {animationColumn}
        </div>
      </div>
    </div>
  );
};
