'use client';

import useTouch from '@/hooks/useTouch';
import cx from 'classnames';
import { FC, useState } from 'react';
import { AnimationFeatureAccountManagementProps } from './AnimationFeatureAccountManagement';
import { AnimationFeatureHelpDeskProps } from './AnimationFeatureHelpDesk';
import { AnimationFeatureSharedInboxProps } from './AnimationFeatureSharedInbox';
import Link from './Link';
import { motion } from 'framer-motion';
import ease from '@/constants/ease';

export interface HighlightedFeatureTile {
  backgroundColor: 'violet' | 'blue' | 'green';
  title: string;
  description: string;
  cta?: {
    title: string;
    href: string;
  };

  animationComponent:
    | FC<AnimationFeatureHelpDeskProps>
    | FC<AnimationFeatureSharedInboxProps>
    | FC<AnimationFeatureAccountManagementProps>;
}

export interface HomeFeatureGridProps {
  title: React.ReactNode;

  highlightedFeatures: HighlightedFeatureTile[];

  features: {
    icon: React.ReactNode;
    cta: {
      title: string;
      href: string;
    };
    description: string;
  }[];

  cta: {
    title: string;
    href: string;
  };
}

const HighlightedFeatureTile: FC<HighlightedFeatureTile> = ({
  backgroundColor,
  title,
  description,
  cta,
  animationComponent,
}) => {
  const isTouch = useTouch();
  const [isHovering, setHovering] = useState(false);

  const Animation = animationComponent;

  const renderedAnimation = <Animation playing={isTouch || isHovering} />;

  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className={cx('rounded-10 flex flex-col justify-between gap-4 overflow-hidden', {
        'bg-violet-300 text-violet-900': backgroundColor === 'violet',
        'bg-blue-300 text-blue-900': backgroundColor === 'blue',
        'bg-green-300 text-green-900': backgroundColor === 'green',
      })}
    >
      <div className="pt-3 px-3 lg:px-4 lg:pt-5 flex flex-col gap-[12px]">
        <h3 className="font-500 tracking-tight text-25/30 lg:-mr-1">{title}</h3>
        <p className="text-17/24">{description}</p>
        {cta && (
          <div className="flex">
            <Link className="block button button-size-medium button-primary-violet" href={cta.href}>
              {cta.title}
            </Link>
          </div>
        )}
      </div>

      <motion.div
        animate={{
          scale: isHovering ? 1.03 : 1,
        }}
        transition={{
          duration: 400 / 1000,
          ease: ease.appear,
        }}
      >
        {renderedAnimation}
      </motion.div>
    </div>
  );
};

const HomeFeatureGrid: FC<HomeFeatureGridProps> = ({ title, highlightedFeatures, features, cta }) => {
  const highlightedFeaturesGrid = (
    <div className="grid lg:grid-cols-3 gap-3 md:gap-gutter-desktop">
      {highlightedFeatures.map((feature, index) => (
        <HighlightedFeatureTile key={index} {...feature} />
      ))}
    </div>
  );

  const featuresGrid = (
    <div className="grid gap-y-6 md:gap-y-4 grid-cols-2 md:grid-cols-4 gap-x-gutter-mobile md:gap-x-gutter-desktop text-gray-900 lg:px-2">
      {features.map((feature, index) => (
        <div
          key={index}
          className="relative p-[12px] duration-200 ease-in-out-quad hover:bg-gray-100 hover:bg-opacity-50 rounded-10"
        >
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2 md:gap-1 md:flex-row md:items-center">
              {feature.icon}
              <p className="font-500 tracking-tight text-17/24">
                <Link href={feature.cta.href} className="link-cover">
                  {feature.cta.title}
                </Link>
              </p>
            </div>
            <p className="text-14/20 text-gray-700">{feature.description}</p>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="my-16 md:my-20 xl:my-25 px-container-mobile md:px-container-desktop">
      <div className="mx-auto max-w-grid flex flex-col gap-8">
        <div className="flex justify-center items-center">
          <h2 className="font-500 tracking-tight text-30/42 md:text-43/52 md:text-center max-w-98">{title}</h2>
        </div>

        {highlightedFeaturesGrid}
        {featuresGrid}

        <div className="flex justify-center items-center">
          <Link className="block button button-size-medium button-secondary-gray" href={cta.href}>
            {cta.title}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeFeatureGrid;
